const purchaseOrderStatusEnum = {
  IDENTIFIED: 'Identified',
  DRAFT: 'Draft',
  COMPLETED: 'Completed',
  SUBMITTED: 'Submitted',
  CHANGES_REQUESTED: 'ChangesRequested',
  REJECTED: 'Rejected',
  ISSUER_INVOICE_CANCELLED: 'IssuerInvoiceCancelled',
  DELETED: 'Deleted',
}

const purchaseOrderDomains = {
  data() {
    return {
      mxPurchaseOrderStatusEnum: purchaseOrderStatusEnum,
      PurchaseOrderTypeEnum: {
        XML: 'Xml',
        MANUAL: 'Manual',
        SEFAZ: 'Sefaz',
      },
    }
  },
  methods: {
    mxIsPurchaseOrderReadOnly(purchaseOrder) {
      return (
        [
          purchaseOrderStatusEnum.COMPLETED,
          purchaseOrderStatusEnum.SUBMITTED,
          purchaseOrderStatusEnum.ISSUER_INVOICE_CANCELLED,
          purchaseOrderStatusEnum.DELETED,
        ].indexOf(purchaseOrder.status) >= 0
      )
    },

    mxPurchaseOrderStatusOptions() {
      return [
        {
          value: purchaseOrderStatusEnum.DRAFT,
          label: this.$t(`PurchaseOrder.Status.${purchaseOrderStatusEnum.DRAFT}`),
          classStyle: 'secondary',
        },
        {
          value: purchaseOrderStatusEnum.COMPLETED,
          label: this.$t(`PurchaseOrder.Status.${purchaseOrderStatusEnum.COMPLETED}`),
          style: { 'background-color': '#008200' },
        },
        {
          value: purchaseOrderStatusEnum.SUBMITTED,
          label: this.$t(`PurchaseOrder.Status.${purchaseOrderStatusEnum.SUBMITTED}`),
          classStyle: 'success',
        },
        {
          value: purchaseOrderStatusEnum.REJECTED,
          label: this.$t(`PurchaseOrder.Status.${purchaseOrderStatusEnum.REJECTED}`),
          classStyle: 'warning',
        },
        {
          value: purchaseOrderStatusEnum.CHANGES_REQUESTED,
          label: this.$t(`PurchaseOrder.Status.${purchaseOrderStatusEnum.CHANGES_REQUESTED}`),
          classStyle: 'warning',
        },
        {
          value: purchaseOrderStatusEnum.IDENTIFIED,
          label: this.$t(`PurchaseOrder.Status.${purchaseOrderStatusEnum.IDENTIFIED}`),
          classStyle: 'info',
        },
        {
          value: purchaseOrderStatusEnum.ISSUER_INVOICE_CANCELLED,
          label: this.$t(
            `PurchaseOrder.Status.${purchaseOrderStatusEnum.ISSUER_INVOICE_CANCELLED}`
          ),
          classStyle: 'danger',
        },
        {
          value: purchaseOrderStatusEnum.DELETED,
          label: this.$t(`PurchaseOrder.Status.${purchaseOrderStatusEnum.DELETED}`),
          classStyle: 'danger',
        },
      ]
    },

    mxGetAverageCostDiffAlertMsg(errorMessage) {
      const itens = JSON.parse(errorMessage || '[]')
      const formatMessage = itens
        .map(
          item =>
            `<br><br>
            <b>#${item?.skuId} - ${item?.name} </b> <br>
            Custo Atual: ${this.$options.filters.currency(item.averageCost || 0)}
            | Preço Unit: ${this.$options.filters.currency(item.unitValue || 0)}`
        )
        .join('')

      const isPlural = itens.length > 1
      const htmlMessage = `
        <b>ATENÇÃO:</b> O${isPlural ? 's' : ''} produto${isPlural ? 's' : ''} abaixo ${
        isPlural ? 'estão' : 'está'
      } com o valor unitário com diferença de mais de 5% em relação ao preço de custo atual:
        <br/> ${formatMessage}
      `
      return `<div style="text-align: left;"> ${htmlMessage} </div>`
    },
  },
}

export default purchaseOrderDomains
