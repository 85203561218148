const delegablePermissions = {
  PDV_PRODUCT_EDIT: {
    name: 'Editar produto',
    permission: 'PayBoxSale.UpdateProduct',
  },
  PDV_DISCOUNT: {
    name: 'Desconto na venda',
    permission: 'PayBoxSale.DiscountApply',
  },
  PDV_PRODUCT_DELETE: {
    name: 'Remover produto',
    permission: 'PayBoxSale.DeleteProduct',
  },
  PDV_CANCEL: {
    name: 'Cancelar venda',
    permission: 'PayBoxSale.Cancel',
  },
  PDV_PRODUCT_ADD_PRODUCT_NEGATIVE_STOCK: {
    name: 'Produto com estoque negativo',
    permission: 'PayBoxSale.AddNegativeStock',
  },
  PDV_SALE_RETURN_OVERFLOW_MAX_VALUE: {
    name: 'Devolução de consignado ultrapassando valor máximo',
    permission: 'PayBoxSale.SaleReturnCanOverflowMaxValue',
  },
  PDV_FINISH_ORDER_NOT_STORE_PRICE_TABLE: {
    name: 'Faturar pedidos com tabela de preço diferente de 1 - Loja',
    permission: 'PayBoxSale.FinishOrderNotStorePriceTable',
  },
  PDV_CANCEL_BLEEDING: {
    name: 'Estornar sangria',
    permission: 'CashBook.CancelBleeding',
  },
  PDV_PRODUCT_ADVANCED_SEARCH: {
    name: 'Pesquisa avançada',
    permission: 'PayBoxSale.SearchProduct',
  },
  PDV_CASH_BOOK_CLOSE_FORCE: {
    name: 'Fecha caixa com divergencia',
    permission: 'CashBook.ForceClose',
  },
  // despesa
  PDV_ACCOUNT_PAYABLE_CREATE: {
    name: 'Criar despesa',
    permission: 'PayBoxAccountsPayable.Create',
  },
  // Bar
  PDV_UPDATE_WAITER_COMISSION: {
    name: 'Atualizar comissão do garçom',
    permission: 'BarTabConsumption.UpdateWaiterComissionOnPdv',
  },

  // ERP
  // ******
  ERP_ORDER_DISCOUNT: {
    name: 'Desconto no pedido',
    permission: 'Sale.DiscountApply',
  },
  ERP_ORDER_ITEM_DISCOUNT: {
    name: 'Desconto unitário no produto do pedido',
    permission: 'Sale.ItemDiscountApply',
  },
  ERP_ORDER_ITEM_PRICE_CHANGE: {
    name: 'Alterar preço unitário de item no pedido (apenas para cima)',
    permission: 'Sale.IncreaseItemUnitPrice',
  },
  ERP_ORDER_DELIVERY_DISCOUNT: {
    name: 'Desconto sobre taxa de entrega',
    permission: 'Sale.DeliveryDiscountApply',
  },
  ERP_ORDER_OVERFLOW_MAX_DELIVERY_DATE: {
    name: 'Data posterior à data de limite para entrega da loja',
    permission: 'Sale.OverflowMaxScheduleDays',
  },
  // pedido de compra
  ERP_PURCHASE_ORDER_DIFF_AVERAGE_PRICE: {
    name: 'Salvar com diferença do preço de custo',
    permission: 'PurchaseOrder.OverflowAveragePriceDiff',
  },
  ERP_MANAGE_TRANSFER_INVOICES: {
    name: 'Permitir enviar nota de transferência',
    permission: 'Invoice.ManageTransferInvoices',
  },
  // gerar nota fiscal
  ERP_INVOICE_GENERATE: {
    name: 'Gerar e emitir nota fiscal eletrônica',
    permission: 'Invoice.Create',
  },

  ERP_INVOICE_MANAGE: {
    name: 'Cancelar Nota Fiscal Eletrônica',
    permission: 'Invoice.Manage',
  },
  // Estoque - inventário
  ERP_STOCK_INVENTORY_DIFF_LIMIT_ON_FINISH: {
    name: 'Trava de diferença no inventário',
    permission: 'StockInventory.FinishWithDiffAboveProfileLimit',
    relatedExceptions: [
      'STOCK_INVENTORY.FINISH_ERROR.OVERFLOW_DIFFERENCE_TOLERANCE_POSITIVE',
      'STOCK_INVENTORY.FINISH_ERROR.OVERFLOW_DIFFERENCE_TOLERANCE_NEGATIVE',
    ],
  },
}

export default delegablePermissions
